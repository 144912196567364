<template>
    <v-dialog v-model="bDialogConfirmResetSIM" persistent @keydown.esc="setDialogConfirmActionGlobal"
        :width="screenWidth">
        <div class="content-card-dialog">
            <v-btn class="content-card-button-close" @click="setDialogConfirmActionGlobal" icon>
                <v-icon color="#000" size="16px"> mdi-close </v-icon>
            </v-btn>
            <p class="content-card-txt-title poppins mb-0">
                Reinicio de tarjeta SIM
            </p>
            <p class="content-card-txt-description poppins mt-2">
                Selecciona la red(es) que deseas reiniciar
            </p>

            <!--#region ACCION REINICIAR TARJETA  -->
            <div v-if="this.oItem.iActionGlobal === 1" class="content-checkboxes-4g-3g2g">
                <div>
                    <v-checkbox v-model="chb4g" label="4G" hide-details color="#2759a2" class="mb-1 mt-0">
                        <template v-slot:label>
                            <p class="txt-label-chb-4g-3g2g mb-0">4G</p>
                        </template>
                    </v-checkbox>
                </div>
                <v-spacer></v-spacer>
                <v-checkbox v-model="chb3g2g" label="3G o 2G" hide-details color="#2759a2" class="mb-1 mt-0 ml-3">
                    <template v-slot:label>
                        <p class="txt-label-chb-4g-3g2g mb-0">3G o 2G</p>
                    </template>
                </v-checkbox>
            </div>
            <!--#endregion ACCION REINICIAR TARJETA  -->

            <!--#region ACCION ENVIAR SMS -->

            <!--#endregion ACCION ENVIAR SMS -->
            <div class="content-buttons">
                <v-container class="pa-0" fluid>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <div class=" display-flex align-items-center justify-content-flex-start">
                                <v-btn @click="setDialogConfirmActionGlobal" class="button-secondary poppins"
                                    elevation="0" width="100%">
                                    Cancelar
                                </v-btn>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" sm="5">
                            <div class=" display-flex align-items-center justify-content-flex-end">
                                <v-btn @click="sendAction" :loading="bLoading" :disabled="valForm()"
                                    class="button-primary poppins" elevation="0" width="100%">
                                    Reiniciar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </v-dialog>
</template>
<script>
export default {
    props: {
        bDialogConfirmResetSIM: Boolean,
        selectedItems: Array,
        oItem: Object
    },
    data() {
        return {
            bLoading: false,
            screenWidth: 0,
            chb4g: false,
            chb3g2g: false,
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth > 600) {
                this.screenWidth = 420 + "px";
                this.screenWidthDialogLaunchTest = 420 + "px";
            } else {
                this.screenWidth = 100 + "%";
                this.screenWidthDialogLaunchTest = 100 + "%";
            }
        },
        setDialogConfirmActionGlobal() {
            this.$emit("setDialogConfirmActionGlobal")
            this.chb4g = false;
            this.chb3g2g = false;
        },
        sendAction() {
            this.bLoading = true;
            this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
                if (this.getPermissionsActionsGlobal()) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.sToken}`,
                        },
                    }, payload = {
                        aICC: this.selectedItems,
                        oNetWorkActions: {
                            bNetwork4g: this.chb4g,
                            bNetwork2g3g: this.chb3g2g,
                        }
                    };
                    DB.put(
                        `${URI}/sim-cards/global/reset`,
                        payload,
                        config
                    ).then((response) => {
                        this.mixSuccess(response.data.message);
                        this.bLoading = false;
                        this.setDialogConfirmActionGlobal();
                    }).catch((error) => {
                        this.mixError(error.response.data.message);
                        this.bLoading = false;
                    });
                } else {
                    this.bLoading = false;
                    this.mixError(this.$store.state.sMessageErrorAccess, 0);
                    this.setDialogConfirmActionGlobal()
                }

            }).catch((error) => {
                this.bLoading = false;
                this.mixError(error.message, error.code);
                this.setDialogConfirmActionGlobal()
            });

        },
        valForm() {
            if (this.oItem.iActionGlobal === 1) {
                return this.chb4g !== true && this.chb3g2g !== true;
            }
        },
        setPayload() {

        }
    }
}
</script>
<style scoped>
.content-checkboxes-4g-3g2g {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    padding-left: 60px;
    padding-right: 60px;
}

.txt-label-chb-4g-3g2g {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
</style>